<template>
  <div>
    <el-drawer
      :title="!type ? $tc('order.Share orders', $isOrderTitle()) : $t('order.Share waybill')"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      size="1060px"
      append-to-body
      v-move-outside
      v-move-draw
    >
      <div class="demo-drawer__content" v-loading="loading">
        <div class="containerLeft" style="width: 260px;">
          <div class="search">
            <div class="title">{{ $t('Edit title') }}</div>
            <el-input v-model="bannerText" size="small" show-word-limit :maxlength="30" :placeholder="$t('Edit title')" />
          </div>
          <div class="directoryList">
            <div class="title">{{ $t('Select background') }}</div>
            <template v-if="directoryList.length">
              <div 
                class="item" 
                :class="directoryActive == item.shareDetailId ? 'active' : ''"
                v-for="item in directoryList" 
                :key="item.shareDetailId"
                @click="jump(item)">
                <img :src="item.fileUrl" />
                <div class="checkbox" v-show="directoryActive == item.shareDetailId">
                  <i class="el-icon-check"></i>
                </div>
              </div>
            </template>
            <el-empty 
              v-else 
              :description="$t('overallSituation.noData')" 
              :image="$emptyImage"
              :image-size="90"
            />
          </div>
        </div>
        <div class="containerRight">
          <template v-if="bannerImage">
            <div class="title">
              <div class="text">{{ $t('Preview') }}</div>
              <div class="operate">
                <div class="label">{{ $t('order.Title Color') }}</div>
                <el-color-picker v-model="titleColor" size="small"></el-color-picker>
              </div>
            </div>
            <div 
              class="contentImage" 
              id="contentImage"
              :class="type == 1 ? 'waybill' : ''">
              <orderShareLink 
                ref="orderShareLink"
                :type="type" 
                :backgroundImage="bannerImage" 
                :backgroundText="bannerText" 
                :orderId="orderId"
                :titleColor="titleColor"
              />
            </div>
            <div class="footer">
              <el-button 
                class="cancel_button" 
                size="small" 
                @click="cancel">
                {{ $t('home.Cancel') }}
              </el-button>
              <el-button size="small" @click="downImage">
                {{ $t('Download image') }}
              </el-button>
              <el-button size="small" :loading="sendEmailLoading" @click="sendEmail">
                {{ $t('Send mail') }}
              </el-button>
              <el-button
                type="primary"
                size="small"
                @click="createLinkEvent"
              >
                {{$t('Generate Link')}}
              </el-button>
            </div>
          </template>
          <el-empty 
            v-else 
            :description="$t('overallSituation.noData')" 
            :image="$emptyImage"
            :image-size="90"
          />
        </div>
      </div>
    </el-drawer>
    <el-dialog
      :title="$t('Send mail')"
      :visible.sync="isEmailDialog"
      @close="emailDialogClose"
      width="358px"
      v-move-outside
    >
      <div class="invitation_box">
        <div class="form_item">
          <el-input
            v-model="emailForm.email"
            :placeholder="$t('home.Email')"
            clearable
            size="small"
          />
        </div>
        <div class="form_item">
          <div class="label">{{ $t('order.Select link validity period') }}</div>
          <div class="effectiveDaysBox">
            <div 
              class="item" 
              v-for="(item, index) in effectiveDaysOptions" 
              :key="index" 
              :class="{
                active: item.id == effectiveDays
              }"
              @click="effectiveDaysClick(item.id)"
            >{{ item.label }}</div>
          </div>
        </div>
        <div class="form_item">
          <div class="containerImage">
            <img :src="shareOrderImageUrl" />
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button 
          class="cancel_button" 
          size="small" 
          @click="emailDialogClose"
        >{{ $t('Cancel') }}</el-button>
        <el-button
          type="primary"
          size="small"
          @click="emailSubmit"
          :loading="emailBtnLoading"
        >
          {{$t('InformationCenter.Send')}}
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="$tc('order.Share orders', $isOrderTitle())"
      :visible.sync="isShareLinkDialog"
      @close="shareLinkDialogClose"
      width="400px"
      v-move-outside
    >
      <div class="invitation_box">
        <div class="form_item">
          <div class="label">{{ $t('order.Select link validity period') }}</div>
          <div class="effectiveDaysBox">
            <div 
              class="item" 
              v-for="(item, index) in effectiveDaysOptions" 
              :key="index" 
              :class="{
                active: item.id == effectiveDays
              }"
              @click="effectiveDaysClick(item.id)"
            >{{ item.label }}</div>
          </div>
        </div>
        <div class="form_item">
          <div class="shareCopyInfo" id="shareCopyInfo" v-if="shareCopyInfo">
            <div class="shareItem">
              <div class="label">{{ $t('Shared by') }}</div>
              <div class="value">{{ shareCopyInfo.name }}</div>
            </div>
            <div class="shareItem">
              <div class="label">{{ $tc('order.OrderNo', $isOrderTitle()) }}</div>
              <div class="value">{{ shareCopyInfo.order }}</div>
            </div>
            <div class="shareItem">
              <div class="label">{{ $t('Files.File Link') }}</div>
              <div class="value">{{ shareCopyInfo.url }}</div>
            </div>
            <div class="shareItem" v-if="effectiveDays != 30000">
              <div class="label">{{ $t('Valid to') }}</div>
              <div class="value">{{ shareCopyInfo.lifespan }}</div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button 
          class="cancel_button" 
          size="small" 
          @click="shareLinkDialogClose"
        >{{ $t('Cancel') }}</el-button>
        <el-button 
          size="small" 
          @click="copyShareLink"
        >{{ $t('Files.Copy Link') }}</el-button>
        <el-button
          type="primary"
          size="small"
          @click="copyAllShareInfo"
        >
          {{ $t('Files.Copy all information') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import html2canvas from "html2canvas";
import { getEmailBannerList } from "@/api/common";
import { sendShareOrderEmail, createShareLink, getShareConfigData } from "@/api/order";
import { uploadFileUrl } from "@/api/files";
import orderShareLink from "../../views/shareOrderLink/index.vue";
export default {
  name: "orderShare",
  components: {
    orderShareLink
  },
  props: {
    type: {
      type: Number,
      default: 0,
    },
    orderId: {
      type: Number | String,
      default: "",
    }
  },
  data() {
    return {
      drawer: false,
      direction: 'rtl',
      loading: false,
      isShareLinkDialog: false,
      emailBtnLoading: false,
      sendEmailLoading: false,
      directoryActive: null,
      directoryList: [],
      bannerText: "全球供应链协作平台",
      bannerImage: "",
      titleColor: "#ffffff",
      effectiveDays: 2,
      shareOrderImageUrl: "",
      emailForm: {
        email: "",
      },
      isEmailDialog: false,
      shareCopyInfo: null,
      effectiveDaysOptions: [{
        id: 2,
        label: 2+this.$t('day')
      }, {
        id: 7,
        label: 7+this.$t('day')
      }, {
        id: 30,
        label: 30+this.$t('day')
      }, {
        id: 30000,
        label: this.$t('Long term')
      }]
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'orderProgress', 'shareDetailId'])
  },
  methods: {
    open() {
      this.drawer = true;
      this.getDirectoryList();
    },
    handleClose() {
      this.drawer = false;
      this.directoryActive = null;
      this.bannerImage = null;
    },
    getDirectoryList() {
      getShareConfigData(this.shareDetailId || 0).then((data) => {
        this.bannerText = data.title;
        this.directoryList = data.children;
        if(this.directoryList.length) {
          this.jump(this.directoryList[0])
        }
      });
    },
    cancel() {
      this.directoryActive = null;
      this.bannerImage = null;
      this.drawer = false;
    },
    createLinkEvent() {
      this.isShareLinkDialog = true;
      this.effectiveDaysClick(2)
    },
    copyShareLink() {
      const theClipboard = navigator.clipboard;
      theClipboard.writeText(this.shareCopyInfo.url).then(() => {
        this.$message.success(this.$t('Files.Copy succeeded'));
      });
    },
    copyAllShareInfo() {
      const contentDom = document.querySelector('#shareCopyInfo');
      var selection = window.getSelection();
      var range = document.createRange();
      range.selectNodeContents(contentDom);
      selection.removeAllRanges();
      selection.addRange(range);
      setTimeout(() => {
        // document.execCommand('Copy')
        const theClipboard = navigator.clipboard;
        theClipboard.writeText(selection.toString()).then(() => {
          this.$message.success(this.$t('Files.Copy succeeded'));
        });
      }, 50);
    },
    downImage() {
      html2canvas(document.querySelector("#shareOrderBox"), {
        useCORS: true,
        allowTaint: true,
        background: '#F7F9FC',
      }).then((canvas) => {
        const oImg = new Image();
        oImg.src = canvas.toDataURL(); // 导出图片
        canvas.toDataURL("image/png", 1);
        // 下载图片
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = oImg.src;
        // 设置下载标题
        a.download = "orderShare";
        a.click();
      });
    },
    effectiveDaysClick(id) {
      this.effectiveDays = id;
      if(this.isShareLinkDialog) {
        createShareLink({
          orderId: this.orderId,
          title: this.bannerText,
          imagePath: this.bannerImage,
          type: this.type,
          titleColor: this.titleColor,
          effectiveDays: this.effectiveDays
        }).then((data) => {
          this.shareCopyInfo = {
            name: this.userInfo.nickName || '',
            order: this.orderId,
            url: data,
            lifespan: this.getLifespanTime(this.effectiveDays)
          }
        }).finally(() => {})
      }
    },
    getLifespanTime(val) {
      let today = new Date().getTime();
      let lastDay = today + 60 * 60 * 1000 * 24 * val; //获取30天后的日期
      return this.$dayjs(lastDay).format("YYYY-MM-DD HH:mm:ss");
    },
    shareLinkDialogClose() {
      this.effectiveDays = 2;
      this.isShareLinkDialog = false;
      this.shareCopyInfo = null;
    },
    shareLinkSubmit() {
      
    },
    emailDialogClose() {
      this.emailForm.email = "";
      this.effectiveDays = 2;
      this.isEmailDialog = false;
    },
    emailSubmit() {
      if(!this.emailForm.email) {
        this.$message.warning(this.$t('registerLoging.PleaseinputaEmail'));
        return;
      }
      this.emailBtnLoading = true;
      sendShareOrderEmail({
        mail: this.emailForm.email,
        orderId: this.orderId,
        title: this.bannerText,
        titleColor: this.titleColor,
        imageUrl: this.shareOrderImageUrl,
        backgroundUrl: this.bannerImage,
        type: this.type,
        effectiveDays: this.effectiveDays || 7,
      }).then(() => {
        this.$message.success(this.$t('home.Sent successfully'))
      }).finally(() => {
        this.emailDialogClose();
        this.emailBtnLoading = false;
      })
    },
    sendEmail() {
      this.sendEmailLoading = true;
      html2canvas(document.querySelector("#shareOrderBox"), {
        useCORS: true,
        allowTaint: true,
        background: '#F7F9FC',
      }).then(async (canvas) => {
        let that = this
        const oImg = new Image();
        oImg.src = canvas.toDataURL(); // 导出图片
        canvas.toDataURL("image/png", 1);
        const data_up = new FormData();
        data_up.append("files", this.base64ToFile(oImg.src));
        data_up.append("configCode", "file_mail_banner");
        await uploadFileUrl(data_up).then((data) => {
          if(!data) return;
          this.shareOrderImageUrl = data[0].savePath;
        });
        this.sendEmailLoading = false;
        this.isEmailDialog = true;
      });
    },
    base64ToFile(data) {
      let base = window.atob(data.substring(data.indexOf(',') + 1))
      let length = base.length
      let url = new Uint8Array(length)
      while (length--) {
        url[length] = base.charCodeAt(length)
      }
      let file = new File([url], `${new Date().getTime()}.jpg`, {
        type: 'image/jpg'
      })
      return file;
    },
    jump(row) {
      this.directoryActive = row.shareDetailId;
      this.bannerImage = row.fileUrl;
    }
  },
}
</script>

<style lang="less" scoped>
.search {
  padding: 18px;
  .title {
    font-size: 14px;
    color: #637381;
    margin-bottom: 5px;
    line-height: 19.6px;
  }
}
.directoryList {
  padding: 18px;
  overflow: overlay;
  height: calc(100% - 93px);
  .title {
    font-size: 14px;
    color: #637381;
    margin-bottom: 5px;
    line-height: 19.6px;
  }
  .item {
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #ffffff;
    position: relative;
    margin-bottom: 12px;
    img {
      width: 100%;
      height: 140px;
      object-fit: cover;
      border-radius: 4px;
    }
    &.active {
      border: 2px solid #48AC42;
    }
    .checkbox {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 24px;
      height: 24px;
      background: #48AC42;
      border-radius: 4px 4px 0 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 18px;
        color: #ffffff;
      }
    }
  }
}
.containerRight {
  background: #F7F9FC;
  position: relative;
  padding: 0;
  min-width: 0;
  .title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 58px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    z-index: 100;
    .text {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      color: #122545;
    }
    .operate {
      display: flex;
      align-items: center;
      gap: 15px;
      .label {
        font-size: 14px;
        color: #637381;
      }
    }
  }
  .contentImage {
    width: 100%;
    height: 100%;
    padding-top: 58px;
    padding-bottom: 86px;
    overflow: overlay;
    &.waybill {
      display: flex;
      align-items: center;
    }
    iframe {
      width: 85%;
      height: calc(100% + 10px);
      margin-top: -10px;
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 86px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
  }
}
.invitation_box {
  margin-top: 3px;
  .form_item {
    margin-bottom: 10px;
    .label {
      font-size: 12px;
      line-height: 22px;
      color: #A3B0C6;
      margin-bottom: 6px;
    }
    .shareCopyInfo {
      width: 100%;
      background: #F7F9FC;
      padding: 12px;
      border-radius: 4px;
      margin-top: 20px;
      .shareItem {
        display: flex;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
        .label {
          width: 62px;
          margin: 0;
          font-size: 12px;
          line-height: 22px;
        }
        .value {
          flex: 1;
          color: #122545;
          font-size: 14px;
          font-weight: 600;
          line-height: 22px;
        }
      }
    }
    .effectiveDaysBox {
      display: flex;
      gap: 13px;
      .item {
        width: 80px;
        height: 40px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #DCE2EC;
        color: #A3B0C6;
        font-size: 12px;
        line-height: 22x;
        font-weight: 600;
        cursor: pointer;
        &.active {
          background: #004C97;
          border: 1px solid #004C97;
          color: #ffffff;
        }
      }
    }
    .containerImage {
      width: 100%;
      height: 200px;
      border-radius: 4px;
      overflow: hidden;
      margin-top: 12px;
      img {
        width: 100%;
      }
    }
  }
}
</style>